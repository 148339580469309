<template>
  <div>
    <div class="top">
      <div class="menu" @click="menuCli">
        <img src="../../../public/menu.png" alt="" srcset="" />
      </div>
      <div class="icon">
        <img
          src="../../../public/logo.png"
          alt=""
          srcset=""
          class="iconImg"
        />浙江名都家具有限公司
      </div>
      <!-- <van-overlay :show="show" @click="over" /> -->
      <van-popup
        v-model="show"
        position="left"
        :style="{ height: '100vh', width: '60%' }"
      >
        <div class="cell-box">
          <div class="cell-a" @click="goto('/mHome')">首页</div>
          <div class="cell-a" @click="goto('/mAbout')">关于我们</div>
          <div class="cell-a" @click="goto('/mCase')">经典案例</div>
          <div class="cell-a" @click="goto('/mContact')">联系我们</div>
        </div>
      </van-popup>
    </div>
  </div>
</template>
<script>
export default {
  name: "mobileTop",
  data() {
    return {
      show: false,
    };
  },
  created() {
    console.log(this.$route.path);
  },
  methods: {
    menuCli() {
      console.log("菜单");
      this.show = true;
    },
    over() {
      this.show = !this.show;
    },
    goto(path){
        if(path != this.$route.path){
            this.$router.push(path);
        }
    }
  },
};
</script>
<style scoped>
.top {
  width: 100%;
  position: relative;
  /* display: flex; */
  /* align-items: center; */
}
.icon {
  text-align: center;
  line-height: 1.1rem;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}
.iconImg {
  width: 1rem;
}
.menu {
  position:absolute;
  top: 0.2rem;
  left: 0.2rem;
  img {
    width: 0.7rem;
  }
}
.cell-box{
    margin-top: 0.5em;
    margin-left: 0.3em;
}
.cell-a {
  font-size: 1.3em;
  padding: 0.3em 0.1em;
  color: #000;
  border-bottom: 1px solid #0000001a;
  margin-bottom: 0.5em;
}
</style>
