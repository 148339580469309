<template>
  <div class="topMu" id="topMu">
    <div class="top">
      <div class="topL">
        <div>
          <img
            src="../../public/logo.png"
            alt=""
            srcset=""
            style="width: 80px"
          />
          <span class="name">浙江名都家具有限公司</span>
        </div>
      </div>
      <div class="topR">
        <div class="item" @click="toHome">首页</div>
        <div class="item" @click="toAbout">关于我们</div>
        <!-- <div class="item" @click="toProduct">产品系列</div> -->
        <div class="item" @click="toCase">经典案例</div>
        <div class="item" @click="toContact">联系我们</div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: "headTop",
  data() {
    return {};
  },
  mounted: function () {
    this.path = this.$route.path;
    console.log(
      this.path,
      this.$router.options.routes,
      this.$router.options.routes.findIndex((item) => item.path == this.path)
    );
    let i =
      this.$router.options.routes.findIndex((item) => item.path == this.path) -
      1;
    console.log(document.getElementsByClassName("item")[i].style);
    let item = document.getElementsByClassName("item")[i];
    item.style.backgroundColor = "#d59770";
    item.style.color = "#fff";
    // window.addEventListener("scroll", this.handleScroll, true); // 监听（绑定）滚轮滚动事件
  },
  methods: {
    toHome() {
      let a = this.$route.path;
      console.log(a);
      if (a == "/home") {
        console.log("4444");
        return;
      } else {
        this.$router.push("/home");
        return;
      }
    },
    toAbout() {
      let a = this.$route.path;
      console.log(a);
      if (a == "/about") {
        console.log("4444");
        return;
      } else {
        this.$router.push("/about");
        return;
      }
    },
    toProduct() {
      let a = this.$route.path;
      console.log(a);
      if (a == "/product") {
        console.log("4444");
        return;
      } else {
        this.$router.push("/product");
        return;
      }
    },
    toCase() {
      let a = this.$route.path;
      console.log(a);
      if (a == "/case") {
        console.log("4444");
        return;
      } else {
        this.$router.push("/case");
        return;
      }
    },
    toContact() {
      let a = this.$route.path;
      console.log(a);
      if (a == "/contact") {
        console.log("4444");
        return;
      } else {
        this.$router.push("/contact");
        return;
      }
    },
    // handleScroll: function () {
    //   console.log('滑动',document.documentElement.scrollTop)
    //   let scrollT=document.documentElement.scrollTop
    //   let topMu=document.getElementById('topMu')
    //   if(scrollT>80){
    //     topMu.style.backgroundColor='#fff'
    //     console.log('121')
    //   }else if(scrollT==0){
    //     topMu.style.backgroundColor='#ffffff21'
    //   }
    //   // let clientHeight =
    //   //   document.documentElement.clientHeight || document.body.clientHeight;
    //   // // 设备/屏幕高度
    //   // let scrollObj = document.getElementById(div); // 滚动区域
    //   // let scrollTop = scrollObj.scrollTop; // div 到头部的距离
    //   // let scrollHeight = scrollObj.scrollHeight; // 滚动条的总高度
    //   // //滚动条到底部的条件
    //   // if (scrollTop + clientHeight == scrollHeight) {
    //   //   // div 到头部的距离 + 屏幕高度 = 可滚动的总高度
    //   // }
    // },
  },
};
</script>

<style scoped>
.topMu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: #fff;
  /* background-color: #ffffff21; */
}
.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5%;
  /* height: 80px; */
}
.topR .item {
  color: black;
  display: inline-block;
  white-space: nowrap;
  /* padding: 0 10px; */
  width: 6em;
  text-align: center;
  /* height: 80px; */
  line-height: 80px;
}
.top .item:hover {
  background-color: #d59770;
  color: #fff;
  cursor: pointer;
}
.topR {
  display: flex;
  padding: 0 5px;
}
.name {
  font-weight: 600;
}
</style>
