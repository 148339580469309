//对于axios进行二次封装
import axios from "axios";

//1.利用axios对象的方法create，去创建axios实例
//2.instance就是axios，就是做了一些配置
const instance = axios.create({
  //配置对象
  //基础路径
  baseURL: 
  // "http://192.168.1.21:3000",
  "https://api.cctvmingdu.com",
  // 'http://121.43.152.148:3000',
  //超时时间
  timeout: 5000,
});
//请求拦截器
instance.interceptors.request.use((config) => {
  //携带token
  // 'Authorization':localStorage.getItem('token');
  config.headers["Authorization"] = localStorage.getItem("token");
  return config;
});

//响应拦截器
instance.interceptors.response.use(
  (res) => {
    return res.data;
  },
  (error) => {
    //响应失败的回调函数，终结promise链
    return error;
    // Promise.reject(new Error('failed' + error))
  }
);

export default instance;
