//当前模块，api进行统一管理
import request from "./request";

export const add = (data) => {
  return request({
    url: "/api/add",
    method: "post",
    data,
  });
};
export const sel = (data) => {
  return request({
    url: "/api/sel",
    method: "post",
    data,
  });
};

export const selectBanner = (data) => {
  return request({
    url: "/banner/select",
    method: "get",
    data,
  });
};
export const caseImg = (data) => {
  return request({
    url: "/case/select",
    method: "get",
    data,
  });
};

export const login = (data) => {
  return request({
    url: "/api/login",
    method: "post",
    data,
  });
};
export const bannerDel = (data) => {
  return request({
    url: "/master/banner/del",
    method: "post",
    data,
  });
};

export const bannerAdd = (data) => {
  return request({
    url: "/master/banner/add",
    method: "post",
    data,
  });
};

export const caseUrlAdd = (data) => {
  return request({
    url: "/master/caseUrl/add",
    method: "post",
    data,
  });
};

export const caseUrlListUpdate = (data) => {
  return request({
    url: "/master/caseUrlList/update",
    method: "post",
    data,
  });
};
export const caseNameUpdate = (data) => {
  return request({
    url: "/master/caseName/update",
    method: "post",
    data,
  });
};
export const caseAdd = (data) => {
  return request({
    url: "/master/case/add",
    method: "post",
    data,
  });
};

export const caseDel = (data) => {
  return request({
    url: "/master/case/del",
    method: "post",
    data,
  });
};