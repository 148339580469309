<template>
  <div id="app">
    <!-- 主体内容 -->
    <!-- <Apple></Apple> overflow: scroll -->
    <router-view style="height: 100vh;" class="routerV"> </router-view>
  </div>
</template>

<script>

export default {
  name: "App",
  components: {},
  // 禁止web端屏幕缩放
  created() {
    window.addEventListener(
      "mousewheel",
      function (event) {
        if (event.ctrlKey === true || event.metaKey) {
          event.preventDefault();
        }
      },
      { passive: false }
    );
  }
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
  font-size: 18px;
  overflow-x: hidden !important;
}

::v-deep .anchorBL {
  display: none;
}
::v-deep .el-image-viewer__mask {
  position: fixed;
}
* {
  overflow-x: hidden;
}

::v-deep .el-tabs__content{
  height: 100%;
  overflow: scroll !important;
}
</style>
