//配置路由的地方
import Vue from "vue";
import Router from "vue-router";
//引入路由组件
// import Login from "@/pages/Login"

//使用插件
Vue.use(Router);

// 公共路由
export const constantRoutes = [
  {
    path: "/",
    redirect: "/pc",
    // redirect: "/houtai",
  },
  {
    path: "/home",
    component: () => import("@/pages/home"),
  },
  {
    path: "/about",
    component: () => import("@/pages/about"),
  },
  // {
  //   path:'/product',
  //   component: () => import("@/pages/product"),
  // },
  {
    path: "/case",
    component: () => import("@/pages/case"),
  },
  {
    path: "/contact",
    component: () => import("@/pages/contact"),
  },
  {
    path: "/mHome",
    component: () => import("@/pages/mobile/mHome"),
  },
  {
    path: "/mAbout",
    component: () => import("@/pages/mobile/mAbout"),
  },
  {
    path: "/mCase",
    component: () => import("@/pages/mobile/mCase"),
  },
  {
    path: "/mContact",
    component: () => import("@/pages/mobile/mContact"),
  },
  {
    path: "/houtai",
    component: () => import("@/pages/login-h.vue"),
  },
  {
    path: "/houtaiNav",
    component: () => import("@/pages/houtai.vue"),
  },
  {
    path: "/pc",
    component: () => import("@/pages/pc"),
  },
  //后管
  {
    path: "/login",
    component: () => import("@/pages/master/login.vue"),
  },
  {
    path: "/mastSet",
    component: () => import("@/pages/master/set.vue"),
  },
];

// 防止连续点击多次路由报错
let routerPush = Router.prototype.push;
let routerReplace = Router.prototype.replace;
// push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((err) => err);
};
// replace
Router.prototype.replace = function push(location) {
  return routerReplace.call(this, location).catch((err) => err);
};

export default new Router({
  mode: "history", // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes,
});
