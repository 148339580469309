import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "@/router";
import "@/assets/style/reset.css";
import headTop from "./com/main.vue";
import botMain from "./com/bottom.vue";
import mobileTop from "./com/mobile/top.vue";
import bannerSet from "./com/master/banner.vue";
import caseSet from "./com/master/case.vue";
import houtai from "./pages/houtai.vue";
import Vant from "vant";
import "vant/lib/index.css";

Vue.use(Vant);
Vue.use(ElementUI);
Vue.config.productionTip = false;

Vue.component("headTop", headTop);
Vue.component("botMain", botMain);
Vue.component("mobileTop", mobileTop);
Vue.component("bannerSet", bannerSet);
Vue.component("caseSet", caseSet);
Vue.component("houTai", houtai);
import baiduMap from "vue-baidu-map";
Vue.config.productionTip = false;
Vue.use(baiduMap, {
  ak: "Cs2a2kR9hxd4YZR5Qx4wCgPLmOV0jBr1",
});

new Vue({
  render: (h) => h(App),
  //挂载路由
  router,
}).$mount("#app");
