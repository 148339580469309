<template>
  <div class="tableC">
    <el-table :data="tableData" style="width: 100%">
      <el-table-column type="index" label="序号" width="50"> </el-table-column>
      <el-table-column prop="name" label="姓名" width="180"> </el-table-column>
      <el-table-column prop="phone" label="联系方式" width="180">
      </el-table-column>
      <el-table-column prop="email" label="邮箱" width="180"> </el-table-column>
      <el-table-column prop="nav" label="反馈信息"> </el-table-column>
    </el-table>
    <el-pagination
      layout="prev, pager, next"
      :total="page.total"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import { sel } from "../API/index";
import qs from "qs";
export default {
  name: "houTai",
  data() {
    return {
      tableData: [],
      sizedata: {
        size: 10,
        page: 1,
      },
      page: {
        total: 0,
      },
    };
  },
  props: {
    index: {
      type: String,
      default: "0",
    },
  },
  created() {},
  watch:{
    index(){
      
      if (this.index == 2) {
        this.getdata();
      }
    }
  },
  methods: {
    getdata() {
      sel(qs.stringify(this.sizedata)).then((res) => {
        console.log(res);
        this.tableData = res.message;
        this.page.total = res.sum;
      });
    },
    handleCurrentChange(val) {
      //   console.log(val);
      this.sizedata.page = val;
      sel(qs.stringify(this.sizedata)).then((res) => {
        console.log(res);
        this.tableData = res.message;
        this.page.total = res.sum;
      });
    },
  },
};
</script>

<style>
.tableC {
  width: 80%;
  margin: 20px auto;
}
</style>
