<template>
  <div>
    <div class="title">首页轮播图图片修改</div>
    <el-divider></el-divider>
    <div class="dis-flex">
      <div v-for="(item, index) in imgList" :key="index" class="wid-30">
        <el-image
          style="width: 100%"
          :src="item.url"
          :preview-src-list="item.url.split(',')"
        >
        </el-image>
        <div class="abs" @click="del(item)">
          <van-icon name="cross" size="25px" />
        </div>
      </div>
      <div class="addPicBox">
        <!-- <el-upload
          action="http://192.168.1.21:3000/upload/pic1"
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
        >
          <i class="el-icon-plus"></i>
        </el-upload> -->
        <el-upload
          class="avatar-uploader"
          action="https://api.cctvmingdu.com/upload/pic1"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
        >
          <!-- <img v-if="form.img" :src="picture + form.img" class="avatar" /> -->
          <i class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </div>
    </div>
  </div>
</template>

<script>
import { selectBanner, bannerDel, bannerAdd } from "@/API/index";
import qs from "qs";
export default {
  name: "bannerSet",
  data() {
    return {
      imgList: [],
    };
  },
  props: {
    index: {
      type: String,
      default: "0",
    },
  },
  created() {
    this.getdata();
    // this.index == 0
  },
  watch:{
    index(){
      if(this.index == 0){
        this.getdata()
      }
    }
  },
  methods: {
    getdata() {
      selectBanner().then((res) => {
        console.log(res);

        if (res.status != 0)
          return this.$message({
            message: "轮播图获取失败请联系管理员",
            type: "warning",
          });
        this.imgList = res.message;
      });
    },
    del(item) {
      console.log(item);
      this.$alert("提示", "确认删除此图片", {
        confirmButtonText: "确定",
        callback: (action) => {
          // this.$message({
          //   type: "info",
          //   message: `action: ${action}`,
          // });
          if (action == "confirm") {
            console.log("1");
            bannerDel(qs.stringify({ id: item.id })).then((res) => {
              if (res.status == 0) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.getdata();
              } else {
                this.$message({
                  message: res.message,
                  type: "warning",
                });
              }
            });
          }
        },
      });
    },

    //图片上传
    handleAvatarSuccess(res, file) {
      console.log("file", file);
      if (file.status == "success") {
        bannerAdd(qs.stringify({ url: file.response.url })).then((res) => {
          console.log(res);
          if (res.status == 0) {
            this.$message({
              message: "添加成功",
              type: "success",
            });
            this.getdata();
          }
        });
      }
      //获取到file下面的响应数据
      // this.form.img = file.response.data;
      // console.log("图片路径：", this.form.menuIcon);
    },
    //判断用户上传的图片格式和大小
    beforeAvatarUpload(file) {
      // const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 10;
      // if (!isJPG) {
      //   this.$message.error("上传头像图片只能是 JPG 格式!");
      // }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
      }
      return isLt2M;
      // return isJPG && isLt2M;
    },

    upload(file) {
      console.log(file);
    },
  },
};
</script>

<style>
.title {
  font-size: 25px;
  margin-top: 20px;
}
.dis-flex {
  display: flex;
  flex-wrap: wrap;
}
.wid-30 {
  width: 30%;
  margin: 20px 1.5%;
  position: relative;
}
.abs {
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ffffffb0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.addPicBox {
  width: 200px;
  height: 200px;
  background-color: #3b3b3b38;
  text-align: center;
  line-height: 200px;
  font-size: 50px;
  position: relative;
  margin:  20px 1.5%;
}
.file-up {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
