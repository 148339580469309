<template>
  <div class="ovsc">
    <div class="title">经典案例修改</div>
    <el-divider></el-divider>
    <el-button type="primary" @click="add()">添加</el-button>
    <!-- {{ index }} -->
    <div class="flexx">
      <div
        v-for="(item, index) in imgList"
        :key="index"
        class="f-item"
        @click="showDetail(item)"
      >
        <div class="img-box">
          <el-image
            style="width: 300px; height: 300px"
            :src="item.urlList.split(',')[0]"
          ></el-image>
          <div class="del-icon" @click.stop="delThis(item)">
            <van-icon name="cross" size="25px" />
          </div>
        </div>
        <div>{{ item.name }}</div>
      </div>
    </div>
    <el-dialog :visible.sync="show">
      <el-input v-model="name" placeholder="请输入内容">
        <el-button slot="append" @click="updatename()">修改名称</el-button>
      </el-input>
      <div class="flexx">
        <div v-for="(item, index) in detailImg" :key="index" class="wid-25">
          <el-image
            style="width: 250px; height: 250px"
            :src="item"
            :preview-src-list="item.split(',')"
          >
          </el-image>
          <div class="abs" @click="del(index)">
            <van-icon name="cross" size="25px" />
          </div>
        </div>

        <div class="addPicBox2">
          <!-- <el-upload
          action="http://192.168.1.21:3000/upload/pic1"
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
        >
          <i class="el-icon-plus"></i>
        </el-upload> -->
          <el-upload
            class="avatar-uploader"
            action="https://api.cctvmingdu.com/upload/pic1"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <!-- <img v-if="form.img" :src="picture + form.img" class="avatar" /> -->
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="show2">
      <el-input v-model="addLIst.name" placeholder="请输入名称">
        <!-- <el-button slot="append" @click="updatename()">名称</el-button> -->
      </el-input>

      <div class="flexx">
        <div
          v-for="(item, index) in addLIst.urlList"
          :key="index"
          class="wid-25"
        >
          <el-image
            style="width: 250px; height: 250px"
            :src="item"
            :preview-src-list="item"
          >
          </el-image>
          <div class="abs" @click="del2(index)">
            <van-icon name="cross" size="25px" />
          </div>
        </div>
        <div class="addPicBox2">
          <el-upload
            class="avatar-uploader"
            action="https://api.cctvmingdu.com/upload/pic1"
            :show-file-list="false"
            :on-success="handleAvatarSuccess2"
            :before-upload="beforeAvatarUpload"
          >
            <!-- <img v-if="form.img" :src="picture + form.img" class="avatar" /> -->
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
      </div>
      <el-button type="primary" @click="addSub()">添加</el-button>
    </el-dialog>
  </div>
</template>

<script>
//
import {
  caseImg,
  caseUrlAdd,
  caseUrlListUpdate,
  caseNameUpdate,
  caseAdd,
  caseDel,
} from "@/API/index";
import qs from "qs";
export default {
  name: "caseSet",
  data() {
    return {
      imgList: [],
      name: "",
      addLIst: {
        name: "",
        idList: [],
        urlList: [],
      },
      show: false,
      show2: false,
      detailMsg: {},
      detailImg: [],
    };
  },
  props: {
    index: {
      type: String,
      default: "",
    },
  },
  created() {},
  watch: {
    index() {
      if (this.index == 1) {
        this.getdata();
      }
    },
  },
  methods: {
    delThis(item) {
      console.log(item.id);

      this.$alert("提示", "确认删除此图片", {
        confirmButtonText: "确定",
        callback: (action) => {
          if (action == "confirm") {
            caseDel(qs.stringify({ id: item.id })).then((res) => {
              console.log(res);
              if (res.status == 0) {
                this.$message.success("删除成功！");
                this.getdata()
              }
            });
          }
        },
      });
    },
    add() {
      this.show2 = true;
    },
    addSub() {
      console.log(this.addLIst);
      let ids = this.addLIst.idList.toString();
      caseAdd(qs.stringify({ name: this.addLIst.name, ids: ids })).then(
        (res) => {
          console.log(res);
          if (res.status == 0) {
            this.$message.success("添加成功");
            this.addLIst = {
              name: "",
              idList: [],
              urlList: [],
            };
            this.show2 = false;
            this.getdata();
          }
        }
      );
    },
    getdata() {
      caseImg().then((res) => {
        console.log(res);
        if (res.status == 0) {
          this.imgList = res.message;
        } else {
          this.$message({
            message: "获取失败",
            type: "warning",
          });
        }
      });
    },
    showDetail(item) {
      this.detailMsg = item;
      console.log(this.detailMsg);
      this.detailImg = item.urlList.split(",");
      this.name = this.detailMsg.name;
      this.show = true;
    },

    //图片上传
    handleAvatarSuccess(res, file) {
      console.log("file", file);
      if (file.status != "success") {
        this.$message.error("上传失败!");
      } else {
        caseUrlAdd(qs.stringify({ url: file.response.url })).then((res) => {
          if (res.status == 0) {
            console.log(res.message.insertId);
            let ids = this.detailMsg.url_id.split(",");
            ids.push(res.message.insertId);
            ids = ids.toString();
            caseUrlListUpdate(
              qs.stringify({ ids: ids, id: this.detailMsg.id })
            ).then((res) => {
              if (res.status == 0) {
                this.detailImg.push(file.response.url);
                this.getdata();
                return this.$message.success("添加成功");
              } else {
                return this.$message.error("添加失败");
              }
            });
            // this.detailMsg.url_id = ids.toString()
            // console.log(this.detailMsg)
            // this.detailMsg.
          }
        });
      }
      //获取到file下面的响应数据
      // this.form.img = file.response.data;
      // console.log("图片路径：", this.form.menuIcon);
    },
    handleAvatarSuccess2(res, file) {
      console.log("file", file);
      if (file.status != "success") {
        this.$message.error("上传失败!");
      } else {
        caseUrlAdd(qs.stringify({ url: file.response.url })).then((res) => {
          if (res.status == 0) {
            console.log(res.message.insertId);
            this.addLIst.urlList.push(file.response.url);
            this.addLIst.idList.push(res.message.insertId);
          }
        });
      }
    },
    del2(index) {
      this.$alert("提示", "确认删除此图片", {
        confirmButtonText: "确定",
        callback: (action) => {
          // this.$message({
          //   type: "info",
          //   message: `action: ${action}`,
          // });
          if (action == "confirm") {
            //   console.log("1");
            console.log(index);
            this.addLIst.idList.splice(index, 1);
            this.addLIst.urlList.splice(index, 1);
          }
        },
      });
    },
    //判断用户上传的图片格式和大小
    beforeAvatarUpload(file) {
      // const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 10;
      // if (!isJPG) {
      //   this.$message.error("上传头像图片只能是 JPG 格式!");
      // }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
      }
      return isLt2M;
      // return isJPG && isLt2M;
    },
    updatename() {
      console.log(this.name);
      let id = this.detailMsg.id;
      caseNameUpdate(qs.stringify({ name: this.name, id: id })).then((res) => {
        console.log(res);
        if (res.status == 0) {
          this.getdata();
          return this.$message.success("修改成功");
        } else {
          return this.$message.error("修改失败");
        }
      });
    },
    del(index) {
      console.log(index);
      let urlList = this.detailMsg.urlList.split(",");
      let idList = this.detailMsg.url_id.split(",");
      let id = this.detailMsg.id;
      console.log(index, urlList, idList, id);
      this.$alert("提示", "确认删除此图片", {
        confirmButtonText: "确定",
        callback: (action) => {
          // this.$message({
          //   type: "info",
          //   message: `action: ${action}`,
          // });
          if (action == "confirm") {
            //   console.log("1");
            idList.splice(index, 1);
            console.log(idList);
            caseUrlListUpdate(
              qs.stringify({ ids: idList.toString(), id: id })
            ).then((res) => {
              if (res.status == 0) {
                this.detailImg.splice(index, 1);
                this.getdata();
                return this.$message.success("删除成功");
              } else {
                return this.$message.error("添加失败");
              }
            });
          }
        },
      });
    },
  },
};
</script>
<style>
.flexx {
  display: flex;
  flex-wrap: wrap;
}
.f-item {
  margin: 15px 30px;
}
.wid-25 {
  width: 250px;
  margin: 20px 10px;
  position: relative;
}
.abs {
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ffffffb0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.addPicBox2 {
  width: 250px;
  height: 250px;
  background-color: #3b3b3b38;
  text-align: center;
  line-height: 250px;
  font-size: 50px;
  position: relative;
  margin: 20px 10px;
}
.img-box {
  width: 300px;
  height: 300px;
  position: relative;
}
.del-icon {
  width: 30px;
  height: 30px;
  font-size: 30px;
  position: absolute;
  border-radius: 50%;
  top: 0;
  right: 0;
  background-color: #ffffffb0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
