<template>
  <div class="bot">
    <div>Address/地址：浙江省湖州市德清县雷甸镇工业区</div>
    <div>Tel/联系电话：13105719688</div>
    <div>Mail/邮箱：wwwjy8811@126.com</div>
    <div>浙ICP备2024099564号-1</div>
  </div>
</template>
<script>
export default {
  name: "botMain"
};
</script>
<style scoped>
.bot {
  background-color: #000;
  color: #fff;
  font-size: 14px;
  display: flex;
  padding: 20px;
  align-items: center;
    flex-direction: column;
    div{
        padding: 1em 0;
    }
}
</style>
